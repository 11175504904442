var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pt-2" }, [
    _c("label", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
    _vm.showPicker
      ? _c(
          "span",
          {
            staticClass: "pull-right btn btn-sm pr-0 pt-0",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                _vm.showPicker = false
              },
            },
          },
          [_c("i", { staticClass: "la la-close" })]
        )
      : _vm._e(),
    _c("div", { staticClass: "kt-input-icon" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchTerm,
            expression: "searchTerm",
          },
        ],
        staticClass: "form-control",
        attrs: {
          disabled: _vm.items.length === 0,
          type: "text",
          placeholder: _vm.placeholder,
        },
        domProps: { value: _vm.searchTerm },
        on: {
          focus: function ($event) {
            return _vm.focusEventHandler($event)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.searchTerm = $event.target.value
          },
        },
      }),
    ]),
    _vm.filteredItems.length > 0 && _vm.showPicker
      ? _c("div", { staticClass: "form-group row p-3 mt-2" }, [
          _c(
            "div",
            { staticClass: "kt-checkbox-list w-100" },
            _vm._l(_vm.filteredItems, function (item) {
              return _c(
                "label",
                {
                  key: `${item.itemId}`,
                  staticClass: "kt-checkbox kt-checkbox--bold kt-font",
                  class: item.itemColor,
                },
                [
                  _c("input", {
                    attrs: { type: "checkbox" },
                    domProps: {
                      value: item.itemId,
                      checked: _vm.selectedIds.indexOf(item.itemId) !== -1,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.emitChanges(item, $event.target.checked)
                      },
                    },
                  }),
                  _vm._v(" " + _vm._s(item.itemName) + " "),
                  _c("small", { staticClass: "text-muted d-block" }, [
                    _vm._v(" " + _vm._s(item.itemDescription) + " "),
                  ]),
                  _c("span", { class: `${item.itemColor}` }),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }