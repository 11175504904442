<template>
<div>
    <CourseSubHeader
        :show-marking-period-picker="true"
        :increment-key="incrementKey"
    >
        <template #buttons>
            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                :title="showCalendars ? 'Hide Calendars' : 'Show Calendars'"
                class="btn btn-icon kt-subheader__btn"
                :class="{ 'active': showCalendars }"
                @click.stop.prevent="showCalendars = !showCalendars"
            >
                <i
                    v-if="showCalendars"
                    class="flaticon2-calendar"
                />
                <i
                    v-else
                    class="flaticon2-calendar-2"
                />
            </a>

            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                :title="showDeleted ? 'Hide Deleted' : 'Show Deleted'"
                class="btn btn-icon"
                :class="{ 'btn-danger': showDeleted, 'btn-clean-danger btn-clean': !showDeleted }"
                @click.stop.prevent="showDeleted = !showDeleted"
            >
                <i
                    class="flaticon2-trash"
                />
            </a>

            <a
                v-b-tooltip.hover.bottomleft
                href="#"

                :title="searchOpen ? 'Hide Filters' : 'Show Filters'"
                class="btn btn-icon kt-subheader__btn"
                :class="{ 'active': searchOpen }"
                @click.stop.prevent="searchOpen = !searchOpen"
            >
                <i
                    v-if="searchOpen"
                    class="flaticon2-search"
                />
                <i
                    v-else
                    class="flaticon2-search"
                />
            </a>
            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                title="Export To CSV"
                class="btn kt-subheader__btn-primary btn-icon"
                @click.stop.prevent="exportData"
            >
                <i class="flaticon-download-1" />
            </a>
        </template>
    </CourseSubHeader>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <!-- <div class="row">
            <div class="col">
                <div
                    class="alert alert-light alert-elevate fade show"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="la la-info-circle kt-font-danger" />
                    </div>
                    <div class="alert-text">
                        Additional improvements are coming to this page after the 2023 Labor Day break.
                        Please don't contact our teams about this page until after the break.
                        Thank you so much for using our software, lots of new improvements are coming soon!
                    </div>
                </div>
            </div>
        </div> -->

        <div class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--lg d-none">
                <div class="kt-portlet__head-label">
                    <span
                        v-if="icon"
                        class="kt-portlet__head-icon pr-0"
                    >
                        <SVGIcon
                            hex-color="#0f88ef"
                            class="mr-3"
                            :name="icon"
                        />
                    </span>
                    <h3 class="kt-portlet__head-title">
                        <span>
                            {{ title }}
                        </span>
                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-actions" />
                </div>
            </div>
            <div class="kt-portlet__body assignments-list">
                <b-collapse
                    :key="`cycle_${resetKey}`"
                    v-model="searchOpen"
                >
                    <div class="form-group row mt-0 mb-1">
                        <div class="col-sm-6 pt-3">
                            <label>Search Term</label>
                            <div class="kt-input-icon kt-input-icon--left">
                                <input
                                    v-model="search.filterOn"
                                    type="text"
                                    class="form-control"
                                    placeholder="Search..."
                                >
                                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                    <span><i class="la la-search" /></span>
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3 pt-3">
                            <label class="">Start Date</label>
                            <b-input-group>
                                <b-form-input
                                    v-model="search.startDate"
                                    type="date"
                                    class="form-control kt-input"
                                    autocomplete="off"
                                />
                                <b-input-group-append>
                                    <b-form-datepicker
                                        v-model="search.startDate"
                                        :date-info-fn="formatCalendarDay"
                                        :no-key-nav="true"
                                        button-only
                                        autocomplete="off"
                                        locale="en-US"
                                        @context="onContextStart"
                                    />
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-sm-3 pt-3">
                            <label class="">End Date</label>
                            <b-input-group>
                                <b-form-input
                                    v-model="search.endDate"
                                    type="date"
                                    class="form-control kt-input"
                                    autocomplete="off"
                                    :disabled="search.startDate == ''"
                                />
                                <b-input-group-append>
                                    <b-form-datepicker
                                        v-model="search.endDate"
                                        :date-info-fn="formatCalendarDay"
                                        :no-key-nav="true"
                                        button-only
                                        autocomplete="off"
                                        locale="en-US"
                                        :disabled="search.startDate == ''"
                                        @context="onContextEnd"
                                    />
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-sm-6 offset-6 pt-3">
                            <MultiPicker
                                :key="gradeTemplateResetKey"
                                :title="'Grading Categories'"
                                :items="gradingCategories"
                                :on-selected="gradingCategorySelected"
                            />
                        </div>
                        <!-- <div class="col-sm-6 pt-3">
                            <MultiPicker
                                :key="googleTopicResetKey"
                                :title="'Google Topics'"
                                :items="googleTopics"
                                :on-selected="googleTopicSelected"
                            />
                        </div>
                        <div class="col-sm-6 pt-3">
                            <MultiPicker
                                :key="googleCategoryResetKey"
                                :title="'Google Category'"
                                :items="googleCategories"
                                :on-selected="googleCategorySelected"
                            />
                        </div> -->
                    </div>
                </b-collapse>

                <div class="form-group row mb-3">
                    <div class="col-12 p-4">
                        <div
                            v-if="error"
                            class="alert alert-solid-danger mb-0 mt-2"
                        >
                            <div class="alert-text">
                                {{ error }}
                            </div>
                            <!-- <a
                                href="#"
                                class="kt-link pull-right"
                                @click.stop.prevent="clearFilters"
                            >
                                Clear All
                            </a> -->
                        </div>
                        <div
                            v-else-if="dataSource.length == 0"
                            class="alert alert-solid-danger mb-0 mt-2"
                        >
                            <div class="alert-text">
                                There are no assignments found. Change search options and try again.
                            </div>
                        </div>
                        <!-- <div
                            v-else-if="allAssignments.length > dataSource.length"
                            class="alert alert-elevate alert-light mb-0 mt-2"
                        >
                            <div class="alert-text">
                                Showing {{ dataSource.length }} of {{ allAssignments.length }}
                                {{ dataSource.length == 1 ? 'assignment' : 'assignments' }}
                            </div>
                            <a
                                href="#"
                                class="kt-link pull-right"
                                @click.stop.prevent="clearFilters"
                            >
                                Clear All
                            </a>
                        </div>
                        <div
                            v-else-if="allAssignments.length == dataSource.length"
                            class="alert alert-solid-brand mb-0 mt-2"
                        >
                            <div class="alert-text">
                                Showing all {{ dataSource.length == 1 ? 'assignment' : 'assignments' }}
                            </div>
                        </div> -->

                        <div class="kt-section__content kt-section__content--solid">
                            <button
                                v-if="search.filterOn"
                                :key="search.filterOn"
                                type="button"
                                class="btn btn-label-primary btn-pill m-2"
                                style="font-size: 12px; overflow: hidden; text-overflow: ellipsis; max-width: 150px; white-space: nowrap;"
                                @click="clearSpecificFilter('term')"
                            >
                                {{ search.filterOn }}
                            </button>
                            <button
                                v-if="selectedLabels.date.length"
                                type="button"
                                class="btn btn-label-primary btn-pill m-2"
                                style="font-size: 12px"
                                @click="clearSpecificFilter('date')"
                            >
                                {{ selectedLabels.date.join(' - ') }}
                            </button>
                            <button
                                v-if="selectedLabels.markingPeriods.length"
                                type="button"
                                class="btn btn-label-info btn-pill m-2"
                                style="font-size: 12px"
                                @click="clearSpecificFilter('markingPeriods')"
                            >
                                {{ selectedLabels.markingPeriods.join(', ') }}
                            </button>
                            <button
                                v-if="selectedLabels.gradeTemplateCategories.length"
                                type="button"
                                class="btn btn-label-success btn-pill m-2"
                                style="font-size: 12px"
                                @click="clearSpecificFilter('gradeTemplateCategories')"
                            >
                                {{ selectedLabels.gradeTemplateCategories.join(', ') }}
                            </button>
                            <button
                                v-if="selectedLabels.googleTopics.length"
                                type="button"
                                class="btn btn-label-primary btn-pill m-2"
                                style="font-size: 12px"
                                @click="clearSpecificFilter('googleTopics')"
                            >
                                G: {{ selectedLabels.googleTopics.join(', ') }}
                            </button>
                            <button
                                v-if="selectedLabels.googleCategories.length"
                                type="button"
                                class="btn btn-label-primary btn-pill m-2"
                                style="font-size: 12px"
                                @click="clearSpecificFilter('googleCategories')"
                            >
                                G: {{ selectedLabels.googleCategories.join(', ') }}
                            </button>
                        </div>
                    </div>

                    <div
                        v-if="showCalendars"
                        id="overflow-container"
                        class="col-12 p-4"
                    >
                        <b-calendar
                            v-for="(calendar, idx) in calendars"
                            :key="`month_${calendar.name}`"
                            class="assignment-calendar"
                            :class="calendar.isThisMonth ? 'this-month' : ''"
                            :data-idx="idx"
                            hide-header
                            label-help=""
                            :value="calendar.startDate"
                            :min="calendar.startDate"
                            :max="calendar.endDate"
                            :no-key-nav="true"
                            :date-info-fn="formatCalendarDay"
                            :selected-variant="'outline-none text-dark' /*this disables the selected date highlight */"
                            @selected="dateSelect"
                        />
                    </div>
                </div>
                <div
                    v-if="dataSource.length > 0 && isPortfolioReady"
                    :key="`key_${title}_${key}`"
                    class="table-container"
                >
                    <AssignmentsHotTable
                        :key="`key_${key}`"
                        ref="wrapperAssignments"
                        :course="courseSection"
                        :data-source="dataSource"
                        :assignments="filteredAssignments"
                        :grade-template="gradeTemplate"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script lang="ts">
import { mapState } from 'vuex';
import moment from 'moment';
import Vue from 'vue';

import googleCourseMixins from '../store/mixins/googleCourseMixins';
import courseMixins from '../store/mixins/courseMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import CourseSubHeader from './CourseSubHeader.vue';
import AssignmentsHotTable from '../components/AssignmentsHotTable.vue';
// import GoogleCategoryMatch from '../components/GoogleCategoryMatch.vue';
import MultiPicker from '../components/MultiPicker.vue';
import studentMixins from '../store/mixins/studentMixins';
import { getCourseWork } from '../store/mixins/courseWorkMixins';

import { populateTeacherTotals } from '../lib/portfolioTotals';

export default Vue.extend({
    name: 'CourseAssignments',
    components: {
        CourseSubHeader,
        AssignmentsHotTable,
        MultiPicker,
    },
    mixins: [
        portfolioMixins,
        teacherMixins,
        googleCourseMixins,
        courseMixins,
        studentMixins,
    ],
    data() {
        return {
            key: 0,
            debounceSearch: null,
            showCalendars: true,
            searchOpen: true,
            resetKey: 1,
            markingPeriodResetKey: 1,
            gradeTemplateResetKey: 1,
            googleCategoryResetKey: 1,
            googleTopicResetKey: 1,
            showDeleted: false,
            error: null,
            calendars: [],
            bulkSelection: [], // this was before upgrade: bulkSelection: (state) => state.portfolio.teacher.bulkSelection,
            search: {
                filterOn: '',
                startDate: '',
                endDate: '',
                gradeTemplateCategories: [],
                markingPeriods: [],
                googleCategories: [],
                googleTopics: [],
            },
            dateReset: null,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            school: (state) => state.user.school,
            deviceType: (state) => state.deviceType,
            googleStudentSubmissions: (state) => state.database.googleStudentSubmissions,
            googleCourseGradeCategories: (state) => state.database.googleCourseGradeCategories,
            googleCourseTopics: (state) => state.database.googleCourseTopics,
            googleCourseRefresh: (state) => state.googleCourseRefresh,
            courseSectionStudents: (state) => state.database.courseSectionStudents,
            students: (state) => state.database.students,
        }),
        isPortfolioReady() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        markingPeriod() {
            const { markingPeriods } = this.$store.state.database;
            const { schoolTermMarkingPeriodId } = this.$store.state.settings;
            if (!schoolTermMarkingPeriodId) return null;
            return markingPeriods.find((m) => m.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        courseSection() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        schoolStaffId() {
            if (!this.teacher) return 0;
            return this.teacher.schoolStaffId;
        },
        schoolEmail() {
            if (!this.teacher) return '';
            return this.teacher.schoolEmail;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        gradeTemplate() {
            if (!this.course) return null;
            return this.course.gradeTemplate;
        },
        courseSections() {
            if (!this.teacher) return [];
            return this.$_courseMixins_getCoursesForStaffId(this.teacher.schoolStaffId);
        },
        allCourseSections() {
            if (!this.teacher) return [];
            return this.$_courseMixins_getCourses();
        },
        googleCourseWork() {
            const { course } = this;
            return this.$store.state.database.googleCourseWork.filter((cw) => cw.googleCourseId == course.googleCourseId);
        },
        hasUnmatchedCategories() {
            if (!this.gradeTemplate) return false;
            const { gradeTemplateId } = this.gradeTemplate;
            return Boolean(
                this.googleCourseGradeCategories
                    .find((c) => !c.gradeTemplateCategoryId || c.gradeTemplateId !== gradeTemplateId),
            );
        },
        markingPeriods() {
            const markingPeriods = this.$store.state.database.markingPeriods.map((mp) => {
                const markingPeriod = { ...mp };
                const startDate = moment(markingPeriod.markingPeriodStart.substr(0, 10), 'YYYY-MM-DD').format('MMM Do YYYY');
                const endDate = moment(markingPeriod.markingPeriodEnd.substr(0, 10), 'YYYY-MM-DD').format('MMM Do YYYY');
                markingPeriod.itemId = `markingPeriod_${mp.schoolTermMarkingPeriodId}`;
                markingPeriod.itemName = mp.markingPeriodName;
                markingPeriod.itemDescription = `${startDate} to ${endDate}`;
                markingPeriod.itemColor = 'Blue';
                return markingPeriod;
            });

            markingPeriods.unshift({
                schoolTermMarkingPeriodId: '0',
                itemId: 'schoolTermMarkingPeriodId_0',
                itemName: 'No Marking Period',
                itemDescription: 'Assignments not in any marking period',
                itemColor: 'Red',
            });
            return markingPeriods;
        },
        googleTopics() {
            const { googleCourseId } = this.course;
            return this.googleCourseTopics
                .filter((a) => a.googleCourseId == googleCourseId)
                .map((t) => {
                    const item = t;
                    item.itemId = `googleCourseTopic_${t.googleCourseTopicId}`;
                    item.itemName = t.topicName;
                    item.itemColor = '';
                    return item;
                });
        },
        googleCategories() {
            const { googleCourseId } = this.course;
            return this.googleCourseGradeCategories
                .filter((a) => a.googleCourseId == googleCourseId)
                .map((t) => {
                    const item = t;
                    item.itemId = `googleCourseGradeCategory_${t.googleCourseGradeCategoryId}`;
                    item.itemName = t.gradeCategoryName;
                    item.itemColor = '';
                    return item;
                });
        },
        gradingCategories() {
            if (!this.gradeTemplate) return [];
            const categories = this.gradeTemplate.categories.map((c) => {
                const category = c;
                category.itemId = `gradeTemplateCategoryId_${c.gradeTemplateCategoryId}`;
                category.itemName = c.categoryName;
                category.itemColor = c.categoryColor;
                return category;
            });
            // categories.push({
            //     gradeTemplateCategoryId: '0',
            //     itemId: 'gradeTemplateCategoryId_0',
            //     itemName: 'Uncategorized',
            //     itemColor: 'Grey',
            //     categoryName: 'Uncategorized',
            // });
            return categories;
        },
        selectedLabels() {
            const labels = {
                date: [],
                gradeTemplateCategories: [],
                markingPeriods: [],
                googleTopics: [],
                googleCategories: [],
            };
            if (this.search.startDate) {
                labels.date.push(moment(this.search.startDate, 'YYYY-MM-DD').format('ll'));
                if (this.search.endDate) labels.date.push(moment(this.search.endDate, 'YYYY-MM-DD').format('ll'));
            }
            if (this.search.gradeTemplateCategories.length > 0) {
                if (this.search.gradeTemplateCategories.length === this.gradingCategories.length) labels.gradeTemplateCategories.push('All grading categories');
                else {
                    const gradeTemplateCategories = this.search.gradeTemplateCategories.map((gtc) => gtc.categoryName);
                    labels.gradeTemplateCategories.push(...gradeTemplateCategories);
                }
            }
            if (this.search.googleTopics.length > 0) {
                if (this.search.googleTopics.length === this.googleTopics.length) labels.googleTopics.push('All Topics');
                else {
                    const googleTopics = this.search.googleTopics.map((gt) => gt.topicName);
                    labels.googleTopics.push(...googleTopics);
                }
            }
            if (this.search.googleCategories.length > 0) {
                if (this.search.googleCategories.length === this.googleCategories.length) labels.googleCategories.push('All Categories');
                else {
                    const googleCategories = this.search.googleCategories.map((gc) => gc.gradeCategoryName);
                    labels.googleCategories.push(...googleCategories);
                }
            }

            return labels;
        },
        allAssignments() {
            const { showDeleted, course } = this;
            const { database } = this.$store.state;

            if (!course) return [];
            const courseWork = getCourseWork(database, course);
            return courseWork.filter((a) => (showDeleted && a.deleted) || !a.deleted);
        },
        filteredAssignments() {
            const { search } = this;
            const { gradeTemplateCategories } = search;
            const { startDate, endDate } = search;
            const { googleTopics, googleCategories } = search;

            const filterOn = (search.filterOn || '').trim().toLowerCase();

            return this.allAssignments.filter((assignment) => {
                // filter on title
                if (filterOn) {
                    const title = (`${assignment.courseWorkTitle}${assignment.courseWorkDescription}` || '').toLowerCase();
                    if (title.indexOf(filterOn) == -1) return false;
                }

                // filter on gradeTemplateCategories
                if (gradeTemplateCategories.length > 0) {
                    const { categoryName } = assignment;
                    const found = gradeTemplateCategories.find((c) => categoryName == c.categoryName) || null;
                    if (!found) return false;
                }

                // filter on googleTopics
                if (googleTopics.length > 0) {
                    const { googleCourseTopicId } = assignment;
                    const found = googleTopics.find((c) => googleCourseTopicId == c.googleCourseTopicId) || null;
                    if (!found) return false;
                }

                // filter on markingPeriods
                const { schoolTermMarkingPeriodId } = this;
                if (schoolTermMarkingPeriodId) {
                    return schoolTermMarkingPeriodId == null || assignment.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId;
                }

                // filter on googleCategories
                if (googleCategories.length > 0) {
                    const { googleCourseGradeCategoryId } = assignment;
                    const found = googleCategories.find((c) => googleCourseGradeCategoryId == c.googleCourseGradeCategoryId) || null;
                    if (!found) return false;
                }

                const assignmentDate = assignment.publishDate ? assignment.publishDate : '';
                if (startDate) {
                    if (endDate != '' && endDate != startDate) {
                        const rangeDate = moment(startDate, 'YYYY-MM-DD');
                        while (rangeDate.isSameOrBefore(moment(endDate, 'YYYY-MM-DD'))) {
                            if (assignmentDate.indexOf(rangeDate.format('YYYY-MM-DD')) > -1) return true;
                            rangeDate.add(1, 'day');
                        }
                        return false;
                    }
                    if (assignmentDate.indexOf(startDate) == -1) return false;
                }

                if (assignment.deleted) return false;

                return true;
            });
        },
        dataSource() {
            const {
                markingPeriods, gradingCategories, courseSections, allCourseSections,
            } = this;
            return this.filteredAssignments.map((cw) => {
                // map items for hot table
                const assignment = { ...cw };
                const markingPeriod = markingPeriods.find((mp) => mp.schoolTermMarkingPeriodId == assignment.schoolTermMarkingPeriodId) || null;
                assignment.markingPeriod = markingPeriod ? markingPeriod.markingPeriod : '';
                assignment.category = gradingCategories.find((category) => category.gradeTemplateCategoryId == assignment.gradeTemplateCategoryId) || null;
                let courseSection = courseSections.find((c) => c.courseSectionId == assignment.courseSectionId) || null;
                if (!courseSection) courseSection = allCourseSections.find((c) => c.courseSectionId == assignment.courseSectionId) || null;
                assignment.courseSection = courseSection;
                assignment.extCourseSectionId = courseSection ? courseSection.extCourseSectionId : '';

                if (assignment.courseWorkDomain == 'Google') {
                    assignment.extCourseWorkId = '';
                    // format publish date and due date
                    if (assignment.publishDate) {
                        assignment.publishDate = moment(assignment.publishDate).format('YYYY-MM-DD');
                        assignment.publishTime = moment(assignment.publishDate).format('HH:mm:ss');
                    }
                    if (assignment.dueDate) {
                        assignment.dueDate = moment(assignment.dueDate).format('YYYY-MM-DD');
                        assignment.dueTime = moment(assignment.dueDate).format('HH:mm:ss');
                    }
                }
                assignment.editData = {
                    courseWorkDomain: assignment.courseWorkDomain,
                    alternateLink: assignment.alternateLink || null,
                    extCourseSectionId: assignment.extCourseSectionId,
                    courseWorkId: assignment.courseWorkId,
                };
                return assignment;
            });
        },
        rangeStart() {
            const { school, search } = this;
            let startDate = moment(school.termStart.substr(0, 10), 'YYYY-MM-DD');

            const { markingPeriods } = search;
            if (markingPeriods.length > 0) {
                const [firstMpStartDate] = markingPeriods;
                startDate = moment(firstMpStartDate.markingPeriodStart, 'YYYY-MM-DD');

                markingPeriods.forEach((mp) => {
                    const markingPeriodStart = moment(mp.markingPeriodStart, 'YYYY-MM-DD');
                    if (markingPeriodStart.valueOf() < startDate.valueOf()) {
                        startDate = markingPeriodStart;
                    }
                });
            }

            return startDate.format('YYYY-MM-DD');
        },
        rangeEnd() {
            const { school, search } = this;
            let endDate = moment(school.termEnd.substr(0, 10), 'YYYY-MM-DD');

            const { markingPeriods } = search;
            if (markingPeriods.length > 0) {
                const [firstMpEndDate] = markingPeriods;
                endDate = moment(firstMpEndDate.markingPeriodEnd, 'YYYY-MM-DD');

                markingPeriods.forEach((mp) => {
                    const markingPeriodEnd = moment(mp.markingPeriodEnd, 'YYYY-MM-DD');
                    if (markingPeriodEnd.valueOf() > endDate.valueOf()) {
                        endDate = markingPeriodEnd;
                    }
                });
            }
            return endDate.format('YYYY-MM-DD');
        },
        canEditAssignments() {
            const { course, user } = this;
            const { teachers, canEditCourse } = course;
            return canEditCourse && teachers.length > 0 && Boolean(teachers.find((t) => t.schoolStaffId == user.school.schoolStaffId));
        },
    },
    watch: {
        search: {
            handler() {
                this.incrementKey();
            },
            deep: true,
        },
        schoolTermMarkingPeriodId() {
            this.incrementKey();
        },
        showCalendars(show) {
            if (show) this.scrollToCurrentMonth();
        },
        extCourseSectionId() {
            this.incrementKey();
        },
        showDeleted() {
            this.incrementKey();
        },
        allAssignments() {
            this.incrementKey();
        },
    },
    mounted() {
        this.generateCalendars();
        this.scrollToCurrentMonth();
    },
    beforeDestroy() {
        const { teacher, $store } = this;
        if (teacher) {
            populateTeacherTotals($store, teacher);
        }
    },
    methods: {
        incrementKey() { // force refresh
            const v = this;
            let { debounceSearch } = this;
            if (debounceSearch) clearTimeout(debounceSearch);
            debounceSearch = setTimeout(() => {
                v.key += 1;
            }, 200);
        },
        scrollToCurrentMonth() {
            setTimeout(() => {
                // @ts-ignore
                const [thisMonth] = document.getElementsByClassName('this-month');
                if (!thisMonth) return;
                const idx = thisMonth.getAttribute('data-idx');
                const { offsetWidth } = thisMonth;
                document.getElementById('overflow-container').scrollLeft = parseInt(idx, 10) * offsetWidth;
            }, 250);
        },
        toggleCalendars() {
            this.showCalendars = !this.showCalendars;
        },
        clearFilters() {
            this.search.startDate = '';
            this.search.endDate = '';
            this.search.filterOn = '';
            this.search.gradeTemplateCategories = [];
            this.search.markingPeriods = [];
            this.search.googleCategories = [];
            this.search.googleTopics = [];
            this.resetKey += 1;
        },
        clearSpecificFilter(filter) {
            switch (filter) {
            case 'term':
                this.search.filterOn = '';
                break;
            case 'date':
                this.search.startDate = '';
                this.search.endDate = '';
                break;
            case 'gradeTemplateCategories':
                this.search.gradeTemplateCategories = [];
                this.gradeTemplateResetKey += 1;
                break;
            case 'markingPeriods':
                this.search.markingPeriods = [];
                this.markingPeriodResetKey += 1;
                break;
            case 'googleCategories':
                this.search.googleCategories = [];
                this.googleCategoryResetKey += 1;
                break;
            case 'googleTopics':
                this.search.googleTopics = [];
                this.googleTopicResetKey += 1;
                break;
            default:
                break;
            }
        },
        markingPeriodSelected(items) {
            this.search.markingPeriods = [...items];
        },
        googleTopicSelected(items) {
            this.search.googleTopics = [...items];
        },
        googleCategorySelected(items) {
            this.search.googleCategories = [...items];
        },
        gradingCategorySelected(items) {
            this.search.gradeTemplateCategories = [...items];
        },
        generateCalendars() {
            const months = [];
            const firstCalendar = this.school.termStart;
            const lastCalendar = this.school.termEnd;

            let startDate = moment(firstCalendar.substring(0, 10), 'YYYY-MM-DD').startOf('month');
            const endDate = moment(lastCalendar.substring(0, 10), 'YYYY-MM-DD').startOf('month');

            months.push({
                name: startDate.format('MMM YYYY'),
                startDate: startDate.startOf('month').format('YYYY-MM-DD'),
                endDate: startDate.endOf('month').format('YYYY-MM-DD'),
                isThisMonth: startDate.format('YYYY-MM') == moment().format('YYYY-MM'),
            });

            do {
                startDate = startDate.add(1, 'month');
                months.push({
                    name: startDate.format('MMM YYYY'),
                    startDate: startDate.startOf('month').format('YYYY-MM-DD'),
                    endDate: startDate.endOf('month').format('YYYY-MM-DD'),
                    isThisMonth: startDate.format('YYYY-MM') == moment().format('YYYY-MM'),
                });
            } while (startDate <= endDate);

            this.calendars = months;
        },
        formatCalendarDay(ymd) {
            const { markingPeriod } = this;

            const output = [];
            const thisDay = moment(ymd, 'YYYY-MM-DD');
            const now = moment();
            const selectedStartDate = moment(this.search.startDate, 'YYYY-MM-DD');
            const selectedEndDate = moment(this.search.endDate, 'YYYY-MM-DD');
            // const rangeStart = moment(this.rangeStart, 'YYYY-MM-DD');
            // const rangeEnd = moment(this.rangeEnd, 'YYYY-MM-DD');

            const isToday = (thisDay.format('YYYY-MM-DD') == now.format('YYYY-MM-DD'));
            const isSelectedDate = this.search.endDate != ''
                ? (selectedStartDate.format('YYYY-MM-DD') <= thisDay.format('YYYY-MM-DD') && selectedEndDate.format('YYYY-MM-DD') >= thisDay.format('YYYY-MM-DD'))
                : (selectedStartDate.format('YYYY-MM-DD') == thisDay.format('YYYY-MM-DD'));

            // color background if today
            if (isSelectedDate) {
                output.push('table-success');
            } else {
                if (isToday) {
                    output.push('table-info');
                }
                if (markingPeriod) {
                    const startDate = moment(markingPeriod.markingPeriodStart.substring(0, 10), 'YYYY-MM-DD');
                    const endDate = moment(markingPeriod.markingPeriodEnd.substring(0, 10), 'YYYY-MM-DD');
                    const isInRange = (thisDay.valueOf() >= startDate.valueOf() && thisDay.valueOf() <= endDate.valueOf());
                    if (isInRange) output.push('table-primary');
                }
            }

            // if weekend, color text red
            const weekday = thisDay.day();
            if (weekday === 0 || weekday === 6) {
                output.push('calendar-weekend');
            }

            // get assignment colors
            const colors = this.filteredAssignments.filter((assignment) => {
                if (assignment.deleted) return false;
                const assignmentDate = assignment.publishDate ? assignment.publishDate : '';
                return assignmentDate.indexOf(ymd) > -1;
            }).map((a) => `${a.color || 'Grey'}`);

            const uniqueColors = [...new Set(colors)];
            const [first, second, third] = uniqueColors;
            if (first) output.push(`calendar-assignment-1-${first}`);
            if (second) output.push(`calendar-assignment-2-${second}`);
            if (third) output.push(`calendar-assignment-3-${third}`);

            return output.join(' ');
        },
        formatDate(date) {
            return moment(date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY');
        },
        onContextStart(ctx) {
            const { selectedYMD } = ctx;
            if (selectedYMD > this.search.endDate && this.search.endDate != '') {
                this.search.startDate = this.search.endDate;
                this.search.endDate = selectedYMD;
            } else { this.validate(selectedYMD); }
        },
        onContextEnd(ctx) {
            const { selectedYMD } = ctx;
            if (selectedYMD < this.search.startDate && selectedYMD != '') {
                this.search.endDate = '';
                this.search.startDate = selectedYMD;
            } else { this.validate(selectedYMD); }
        },
        validate(ymd) {
            this.error = null;
            if (!ymd && this.search.startDate == '') return;
            if (this.search.startDate != '') ymd = this.search.startDate;
            const startDate = this.search.startDate != '' ? this.search.startDate : ymd;
            const endDate = this.search.endDate != '' ? this.search.endDate : ymd;
            const googleCourseWork = this.googleCourseWork.filter((assignment) => {
                const assignmentDate = assignment.dueDate ? assignment.dueDate : assignment.sortDate;
                if (startDate != endDate) {
                    const rangeDate = moment(startDate, 'YYYY-MM-DD');
                    while (rangeDate.isSameOrBefore(moment(endDate, 'YYYY-MM-DD'))) {
                        if (assignmentDate.indexOf(rangeDate.format('YYYY-MM-DD')) > -1) return true;
                        rangeDate.add(1, 'day');
                    }
                }
                return assignmentDate.indexOf(ymd) > -1;
            });
            if (googleCourseWork.length == 0) {
                this.error = `There are no assignments ${startDate != endDate
                    ? `from ${this.formatDate(startDate)} - ${this.formatDate(endDate)}`
                    : `on ${this.formatDate(ymd)}`}`;
            }
        },
        dateSelect(ymd) {
            this.error = null;
            /* Logic for Calendar/Date Range UX */

            if (this.search.startDate > ymd && this.search.startDate != '') {
                this.search.startDate = ymd;
                this.search.endDate = '';
            } else if (this.search.startDate < ymd && this.search.endDate > ymd) {
                this.search.startDate = ymd;
                this.search.endDate = '';
            } else if (this.search.startDate < ymd && this.search.endDate == ymd) {
                this.search.endDate = '';
            } else if (this.search.startDate < ymd && this.search.startDate != '') {
                this.search.endDate = ymd;
            } else if (this.search.endDate == ymd && this.search.startDate == ymd) {
                this.search.startDate = '';
                this.search.endDate = '';
            } else if (this.search.endDate == ymd) {
                this.search.endDate = this.search.startDate;
            } else if (this.search.startDate == ymd || (this.search.startDate == ymd && this.search.endDate != '')) {
                this.search.startDate = '';
                this.search.endDate = '';
            } else this.search.startDate = ymd;

            this.validate();
        },
        clearDate() {
            this.error = null;
            this.search.startDate = '';
            this.search.endDate = '';
        },
        exportData() {
            // eslint-disable-next-line no-underscore-dangle
            const { columns, data } = this.$refs.wrapperAssignments.$refs.wrapper._props.settings;
            const labelsData = columns.map((column) => column.data).filter((column) => column !== 'editCourseWork');
            let csv = columns
                .map((header) => header.title).filter((column) => column !== 'Edit')
                .join(',');
            csv += '\n';
            data.forEach((row) => {
                csv += labelsData
                    .map((cell) => row[cell])
                    .join(',');
                csv += '\n';
            });

            const date = moment(new Date()).format('YYYYMMDD');
            const filename = `${this.course.name}_Assignments_${date}`.replaceAll(' ', '_');
            const anchor = document.createElement('a');
            anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
            anchor.target = '_blank';
            anchor.download = `${filename}.csv`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        },
    },
});
</script>

<style lang="scss" src="../css/colors.scss" />

<style scoped>

div.table-container {
    width: 100%;
    overflow-x: scroll;
}

#overflow-container {
    overflow-x: scroll;
    padding-bottom: 0.4rem;
    display: flex;
    flex-wrap: nowrap;
}

#overflow-container div:first-child {
    margin-left: auto;
}

#overflow-container div:last-child {
    margin-right: auto;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
}
</style>

<style>

.assignment-calendar {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}

.assignment-calendar .b-calendar-inner .b-calendar-grid {
    background-color: #FFFFFF;
    height: 100% !important;
}

.assignment-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-body .bg-light {
    background-color: #FFFFFF !important;
    cursor: default;
}

.assignment-calendar .b-calendar-nav {
    display: none !important;
}

.assignment-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-body .table-info:not(.selected) .active,
.assignment-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-body .table-success .active,
.assignment-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-body .table-success span {
    background-color: transparent !important;
}

.assignment-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-body .table-success,
.assignment-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-body .table-success span {
    cursor: default;
}

.assignment-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-body .table-success:hover {
    background-color: #baecdd !important;
}

.assignment-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-body .selected span {
    background-color: #ffffff !important;
}

.this-month header.b-calendar-grid-caption {
    background-color: #cfd9f9 !important;
}

div.assignments-list div.b-form-date-calendar .form-control {
    padding-left: 0 !important;
}

div.assignments-list div.b-form-btn-label-control .form-control {
    padding-left: 0 !important;
}

.calendar-weekend span {
    font-weight: 500 !important;
    color: #74788d !important;
}
</style>
