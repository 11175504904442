<template>
<div class="pt-2">
    <label>
        {{ title }}
    </label>
    <span
        v-if="showPicker"
        class="pull-right btn btn-sm pr-0 pt-0"
        @click.stop.prevent="showPicker = false"
    >
        <i class="la la-close" />
    </span>
    <div class="kt-input-icon">
        <input
            v-model="searchTerm"
            :disabled="items.length === 0"
            type="text"
            class="form-control"
            :placeholder="placeholder"
            @focus="focusEventHandler($event)"
        >
    </div>

    <div
        v-if="filteredItems.length > 0 && showPicker"
        class="form-group row p-3 mt-2"
    >
        <div class="kt-checkbox-list w-100">
            <label
                v-for="item in filteredItems"
                :key="`${item.itemId}`"
                class="kt-checkbox kt-checkbox--bold kt-font"
                :class="item.itemColor"
            >
                <input
                    type="checkbox"
                    :value="item.itemId"
                    :checked="selectedIds.indexOf(item.itemId) !== -1"
                    @input="emitChanges(item, $event.target.checked)"
                >
                {{ item.itemName }}
                <small class="text-muted d-block">
                    {{ item.itemDescription }}
                </small>
                <span :class="`${item.itemColor}`" />
            </label>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: 'MultiPicker',
    props: {
        title: {
            type: String,
            required: true,
        },
        items: {
            type: Array, // itemId, itemName, selected, ?itemDescription
            required: true,
        },
        onSelected: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            selectedIds: [],
            searchTerm: null,
            showPicker: false,
            timeout: null,
        };
    },
    computed: {
        filteredItems() {
            const searchTerm = (this.searchTerm || '').toLowerCase();
            return this.items.filter((c) => {
                const search = `${c.itemName}`.toLowerCase();
                return (search.indexOf(searchTerm.toLowerCase()) > -1);
            }).sort((a, b) => ((a.itemName > b.itemName) ? 1 : ((b.itemName > a.itemName) ? -1 : 0)));
        },
        placeholder() {
            if (this.items.length === 0) return 'None available';
            if (this.selectedItems.length === 0 || this.selectedItems.length === this.items.length) return 'All';
            return this.selectedItems.map((i) => i.itemName).join(', ');
        },
        selectedItems() {
            const { items, selectedIds } = this;
            return selectedIds.map((itemId) => {
                    const item = items.find((i) => i.itemId == itemId) || null;
                    return item;
                })
                .filter((item) => item !== null)
                .sort((a, b) => ((a.itemName > b.itemName) ? 1 : ((b.itemName > a.itemName) ? -1 : 0)));
        },
    },
    mounted() {
        this.unSelectAll();
    },
    methods: {
        unSelectAll() {
            this.selectedIds = [];
            this.onSelected(this.selectedItems);
        },
        clear() {
            this.searchTerm = '';
            this.unSelectAll();
        },
        focusEventHandler() {
            this.timeout = null;
            this.showPicker = true;
            clearTimeout(this.timeout);
        },
        emitChanges(item, isAddition) {
            if (isAddition) {
                this.selectedIds.push(item.itemId);
            } else {
                this.selectedIds = this.selectedIds.filter((itemId) => itemId !== item.itemId);
            }
            this.onSelected(this.selectedItems);
            this.searchTerm = '';
        },

    },
};
</script>

<style scoped>
.kt-widget4 {
    position: absolute;
    width: 85%;
    z-index: 2;
    background: white;
    border: 1px solid #e2e5ec;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: clip;
}
</style>
