var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.dataSource.length > 0
    ? _c(
        "div",
        { key: `key_${_vm.key}`, staticClass: "w-100 ass-hot-table-container" },
        [
          _c("hot-table", {
            ref: "wrapper",
            attrs: { settings: _vm.hotSettings },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }