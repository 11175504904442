<template>
<div
    v-if="dataSource.length > 0"
    :key="`key_${key}`"
    class="w-100 ass-hot-table-container"
>
    <hot-table
        ref="wrapper"
        :settings="hotSettings"
    />
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Vue from 'vue';
import validate from 'validate.js';
import Types from '../store/Types';
import { hotDefaults } from '../store/hotDefaults';
import hotTableMixins, { setCellBackgroundColor } from '../store/mixins/hotTableMixins';
import panelMixins from '../store/mixins/panelMixins';

export default Vue.extend({
    name: 'AssignmentsHotTable',
    mixins: [hotTableMixins, panelMixins],
    props: {
        course: {
            type: Object,
            required: true,
        },
        dataSource: {
            type: Array,
            required: true,
        },
        gradeTemplate: {
            type: Object,
            required: true,
        },
        assignments: {
            type: Array,
            required: true,
        },
    },
    data() {
        const data = {
            key: 1,
            originals: [],
            hotSettings: {
                autoColumnSize: true,
                autoRowSize: false,
                rowHeights: 48,
                comments: false,
                data: [],
                fixedColumnsStart: 3,
                fillHandle: {
                    direction: 'vertical',
                    autoInsertRow: false,
                },
                hiddenColumns: {
                    columns: [0, 1],
                },
                columns: [
                    {
                        data: 'courseWorkId',
                        title: 'Course Work Id',
                        readOnly: true,
                    },
                    {
                        data: 'googleCourseWorkId',
                        title: 'Google Course Work Id',
                        readOnly: true,
                    },
                    {
                        title: 'Actions',
                        width: 84,
                        data: 'editData',
                        editor: false,
                        readOnly: true,
                        className: 'htCenter htMiddle',
                    },
                    {
                        data: 'markingPeriod',
                        title: 'MP',
                        type: 'dropdown',
                        width: 34,
                        source: [],
                        allowInvalid: false,
                    },
                    {
                        title: 'ID',
                        className: 'htCenter htMiddle',
                        data: 'teacherExtCourseWorkId',
                    },
                    {
                        title: 'Section',
                        data: 'courseSection.courseSectionTitle',
                        readOnly: true,
                        className: 'htCenter htMiddle',
                    },
                    {
                        data: 'categoryName',
                        title: 'Category',
                        type: 'dropdown',
                        width: 160,
                        source: [],
                        allowInvalid: false,
                    },
                    {
                        title: 'Publish',
                        data: 'publishDate',
                        dateFormat: 'YYYY-MM-DD',
                        width: 120,
                        className: 'htCenter htMiddle',
                        correctFormat: true,
                        type: 'date',
                    },
                    {
                        title: 'At',
                        data: 'publishTime',
                        width: 72,
                        type: 'time',
                        className: 'htCenter htMiddle',
                        timeFormat: 'HH:mm:ss',
                        correctFormat: true,
                    },
                    {
                        title: 'Domain',
                        width: 56,
                        data: 'courseWorkDomain',
                        readOnly: true,
                        className: 'htCenter htMiddle',
                    },
                    {
                        title: 'Title',
                        width: 260,
                        data: 'courseSection.courseWorkTitle',
                        className: 'htCenter htMiddle',
                    },
                    {
                        title: 'Max Points',
                        data: 'maxPoints',
                        className: 'htCenter htMiddle',
                        type: 'numeric',
                    },
                    {
                        title: 'Weight',
                        className: 'htCenter htMiddle',
                        data: 'courseWorkWeight',
                        type: 'numeric',
                    },
                    {
                        title: 'Due',
                        data: 'dueDate',
                        width: 120,
                        className: 'htCenter htMiddle',
                        type: 'date',
                        correctFormat: true,
                        dateFormat: 'YYYY-MM-DD',
                    },
                    {
                        title: 'At',
                        data: 'dueTime',
                        width: 72,
                        className: 'htCenter htMiddle',
                        type: 'time',
                        timeFormat: 'HH:mm:ss',
                    },
                    {
                        title: 'Hide',
                        className: 'htCenter htMiddle',
                        data: 'excluded',
                        type: 'checkbox',
                    },
                    {
                        title: 'Hide Marks',
                        data: 'hideMarks',
                        className: 'htCenter htMiddle',
                        type: 'checkbox',
                    },
                ],
                // columnSorting: {
                //     compareFunctionFactory(sortOrder) {
                //         return function (currentValue, nextValue) {
                //             if (!currentValue) {
                //                 return 1;
                //             }
                //             if (!nextValue) {
                //                 return -1;
                //             }

                //             const returnByOrder = sortOrder == 'asc' ? -1 : 1;
                //             if (currentValue < nextValue) {
                //                 return returnByOrder * -1;
                //             }
                //             if (currentValue > nextValue) {
                //                 return returnByOrder;
                //             }
                //             return 0;
                //         };
                //     },
                // },
            },
        };
        data.hotSettings = {
            ...hotDefaults,
            ...data.hotSettings,
        };

        return data;
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        schoolEmail() {
            return this.$route.params.schoolEmail;
        },
        markingPeriods() {
            return this.$store.state.database.markingPeriods.filter((mp) => !mp.deleted);
        },
        gradingCategories() {
            const categories = this.gradeTemplate.categories.map((c) => {
                const category = c;
                category.itemId = `gradeTemplateCategoryId_${c.gradeTemplateCategoryId}`;
                category.itemName = c.categoryName;
                category.itemColor = c.categoryColor;
                return category;
            });
            return categories;
        },
        canEditAssignments() {
            const { course, user } = this;
            const { teachers, canEditCourse } = course;
            return canEditCourse && teachers.length > 0 && Boolean(teachers.find((t) => t.schoolStaffId == user.school.schoolStaffId));
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        getInstance() {
            return this.$refs.wrapper.hotInstance;
        },
        populate() {
            const {
                save, canEditAssignments, editAssignment,
                getInstance, deleteAssignment,
            } = this;
            this.hotSettings.height = document.documentElement.clientHeight - 200;
            this.hotSettings.data = this.dataSource;

            const markingPeriods = this.markingPeriods.map((mp) => mp.markingPeriod);
            const { gradingCategories } = this;
            const getColorForMarkingPeriod = this.$_hotTableMixins_getColorForMarkingPeriod;
            const getColorForCategory = this.$_hotTableMixins_getColorForCategory;
            const setGradeCategoryProperties = this.$_hotTableMixins_setGradeCategoryProperties;
            const setCellColor = this.$_hotTableMixins_setCellColor;

            const { data } = this.hotSettings;

            // attach renderers to custom columns
            const openPanelForCourseWork = this.$_panelMixins_openPanelForCourseWork;

            this.hotSettings.columns.forEach((x, idx, columns) => {
                const column = x;
                if (column.data == 'markingPeriod') {
                    // configure markingPeriods dropdown
                    column.source = markingPeriods;
                    column.validator = (val, callback) => {
                        if (!val) return callback(true);
                        const isValid = markingPeriods.includes(val);
                        callback(isValid);
                    };
                    column.renderer = function (instance, td, row, col, prop, mp) {
                        const element = td;
                        element.style.fontWeight = 'bold';
                        element.style.textAlign = 'center';
                        element.style.verticalAlign = 'middle';
                        const color = getColorForMarkingPeriod(mp);
                        if (color) {
                            setCellColor(element, color);
                        }
                        element.innerText = mp;
                        return element;
                    };
                }

                if (column.title == 'Title') {
                    column.renderer = function (instance, td, row) {
                        const element = td;
                        const assignment = data[row];
                        let { courseWorkTitle } = assignment;
                        const { category } = assignment;

                        if (assignment.deleted) {
                            courseWorkTitle = `Deleted: ${courseWorkTitle}`;
                            setCellBackgroundColor(element, 'Red');
                        }

                        const a = document.createElement('span');
                        a.className = `kt-link font ${category ? category.categoryColor : 'Grey'}`;
                        a.innerText = courseWorkTitle;
                        a.setAttribute('style', 'line-height: 16px; white-space: break-spaces; text-decoration-break: clone;');
                        a.addEventListener('mousedown', (e) => {
                            e.preventDefault();
                            openPanelForCourseWork({ ...assignment });
                        });

                        element.innerHTML = '';
                        element.appendChild(a);

                        return element;
                    };
                }

                if (column.title == 'Actions') {
                    column.renderer = function (instance, td, row, col, prop, value) {
                        const element = td;

                        const assignment = data[row];
                        const container = document.createElement('div');
                        container.className = 'p-2';
                        container.setAttribute('style', 'width: 96px;');

                        const deleteButton = document.createElement('button');
                        deleteButton.className = 'btn btn-sm btn-clean btn-icon btn-icon-md';
                        const deleteIcon = document.createElement('i');
                        deleteIcon.className = 'la la-trash';
                        deleteButton.appendChild(deleteIcon);
                        deleteButton.addEventListener('mousedown', (event) => deleteAssignment(event, assignment));
                        container.appendChild(deleteButton);

                        const editButton = document.createElement('button');
                        editButton.className = 'btn btn-sm btn-clean btn-icon btn-icon-md';
                        const editIcon = document.createElement('i');
                        editIcon.className = 'la la-edit';
                        editButton.appendChild(editIcon);
                        editButton.addEventListener('mousedown', (event) => editAssignment(event, assignment));
                        container.appendChild(editButton);

                        element.innerHTML = '';
                        element.appendChild(container);

                        return element;
                    };
                }

                if (column.data == 'categoryName') {
                    // configure gradingCategories dropdown
                    column.source = gradingCategories.map((c) => c.categoryName);
                    column.validator = (categoryName, callback) => {
                        if (!categoryName) return callback(true);
                        const isValid = gradingCategories.find((c) => c.categoryName == categoryName) || null;
                        callback(Boolean(isValid));
                    };
                    column.renderer = function (instance, td, row, col, prop, categoryName) {
                        // const record = data[row];
                        const element = td;
                        const category = gradingCategories.find((c) => c.categoryName == categoryName);
                        if (!category) return setGradeCategoryProperties(element, 'Uncategorized', 'Grey');
                        const { gradeTemplateCategoryId, gradeTemplateId } = category;
                        const color = getColorForCategory(gradeTemplateCategoryId, gradeTemplateId);
                        if (!color) return setGradeCategoryProperties(element, 'Uncategorized', 'Grey');
                        setGradeCategoryProperties(element, category.categoryName, color);
                    };
                }
            });

            if (!canEditAssignments) {
                const { columns } = this.hotSettings;
                this.hotSettings.columns = columns
                    // remove actions if read-only
                    .filter((col) => col.title !== 'Actions')
                    .map((col) => {
                        // make all columns read-only
                        const column = { ...col };
                        column.readOnly = true;
                        return column;
                    });
            }

            const fieldNames = this.hotSettings.columns.map((c) => c.data);

            // make specific google cells read only
            this.hotSettings.cells = function (row, col) {
                const record = data[row];
                const colName = fieldNames[col];
                const enabledColumns = ['categoryName', 'markingPeriod', 'teacherExtCourseWorkId', 'maxPoints', 'courseWorkWeight', 'excluded', 'hideMarks'];

                if (record.courseWorkDomain == 'Google') {
                    const readOnly = !enabledColumns.includes(colName);
                    if (!canEditAssignments) return { readOnly };
                }

                if (record.deleted) {
                    return {
                        className: 'htCenter htMiddle row-deleted',
                    };
                }

                return {};
            };

            // disable fill handle for certain columns
            this.hotSettings.afterSelection = function (row, col) {
                const colName = fieldNames[col];
                const disabledFillColumns = ['extCourseWorkId', 'courseSection', 'extCourseSectionId', 'courseWorkTitle'];
                if (disabledFillColumns.includes(colName)) {
                    this.updateSettings({
                        fillHandle: false,
                    });
                } else {
                    this.updateSettings({
                        fillHandle: {
                            direction: 'vertical',
                            autoInsertRow: false,
                        },
                    });
                }
            };

            // sends the save event
            this.hotSettings.afterChange = function (changes) {
                if (!changes || changes.length == 0) return;
                const assignments = [];
                changes.forEach(([rowIndex, fieldName, oldValue, newValue]) => {
                    const courseWorkRow = getInstance().getDataAtRow(rowIndex);
                    const courseWork = data.find((i) => (i.courseWorkDomain == 'Local' && i.courseWorkId == courseWorkRow[0])
                        || (i.courseWorkDomain == 'Google' && i.googleCourseWorkId == courseWorkRow[1]));
                    const { courseWorkId } = courseWork;
                    const exists = assignments.find((i) => i.courseWork.courseWorkId == courseWorkId);
                    if (!exists) {
                        assignments.push({
                            courseWork,
                            changes: [],
                        });
                    }
                    const toUpdate = assignments.find((i) => i.courseWork.courseWorkId == courseWorkId);
                    toUpdate.changes.push({ fieldName, oldValue, newValue });
                });
                save(assignments);
            };

            // primative validation
            const maxPoints = this.gradeTemplate.maxPoints ? parseFloat(this.gradeTemplate.maxPoints) : null;
            const maxWeight = this.gradeTemplate.maxWeight ? parseFloat(this.gradeTemplate.maxWeight) : null;

            this.hotSettings.beforeChange = function (changes) {
                const changeArray = changes;
                for (let i = 0; i < changes.length; i += 1) {
                    const key = changes[i][1]; // 1 is the changed cell column index key.
                    const inputValue = changes[i][3]; // 3 is the changed data index.

                    if (key == 'extCourseWorkId') {
                        // console.log({ row, key, data });
                        if (inputValue) {
                            const cleanedValue = (`${inputValue}` || '').trim().substring(0, 4);
                            changeArray[i][3] = cleanedValue;
                        } else {
                            changeArray[i][3] = '';
                        }
                    }

                    if (key == 'maxPoints') {
                        // console.log({ row, key, data });
                        if (!isNumber(inputValue)) {
                            changeArray[i][3] = maxPoints || '1';
                            return;
                        }

                        const floatValue = parseFloat(inputValue);
                        if (maxPoints && floatValue > maxPoints) {
                            changeArray[i][3] = `${maxPoints}`;
                        } else {
                            changeArray[i][3] = `${Math.floor(floatValue)}`;
                        }
                    }

                    if (key == 'courseWorkWeight') {
                        // console.log({ row, key, data });
                        if (!isNumber(inputValue)) {
                            changeArray[i][3] = maxWeight || '1';
                            return;
                        }
                        const floatValue = parseFloat(inputValue);
                        if (maxWeight && floatValue > maxWeight) {
                            changeArray[i][3] = `${maxWeight}`;
                            return;
                        }

                        if (floatValue < 0) {
                            changeArray[i][3] = maxWeight || '1';
                            return;
                        }

                        changeArray[i][3] = `${floatValue}`;
                    }
                }
            };

            // set course colors in row headings
            this.hotSettings.afterGetRowHeader = function (idx, ele) {
                // vert center row #
                const div = ele.firstChild;
                div.className = 'mt-2';
                const row = data[idx];
                const { courseSection } = row;
                if (courseSection) {
                    const { courseSectionHexColor } = courseSection;
                    if (courseSectionHexColor) {
                        // https://stackoverflow.com/a/66143374
                        const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}`;
                        div.parentNode.style = `background-color: ${setOpacity(courseSectionHexColor, 0.2)}`;
                    }
                }
            };

            // style column headers
            this.hotSettings.afterGetColHeader = function (idx, ele) {
                const container = ele.firstChild;
                container.className = 'my-2 py-1 mx-1';
                container.setAttribute('style', 'max-height: 34px; overflow: hidden');
            };

            this.key += 1; // force refresh
        },
        editAssignment(event, val) {
            const { schoolEmail, $router } = this;
            const { courseWorkDomain } = val;
            if (courseWorkDomain == 'Google' && val.alternateLink) {
                window.open(val.alternateLink);
            }
            if (courseWorkDomain == 'Local') {
                const { courseWorkId, extCourseSectionId } = val;
                $router.push({
                    name: 'TeacherLocalCourseAssignmentEdit',
                    params: { schoolEmail, extCourseSectionId, courseWorkId },
                });
            }
            event.preventDefault();
        },
        deleteAssignment(event, val) {
            const assignment = { ...val };
            const { showError, $store, gradeTemplate } = this;
            const { courseWorkDomain } = assignment;
            // u gotta erase google via google iu
            if (courseWorkDomain == 'Google' && assignment.alternateLink) {
                window.open(assignment.alternateLink);
            }

            if (courseWorkDomain == 'Local') {
                assignment.deleted = !assignment.deleted;
                $store.dispatch(Types.actions.EDIT_LOCAL_COURSE_WORK, {
                    courseWorks: [assignment],
                    gradeTemplate,
                    callback(err) {
                        if (err) {
                            console.error(err);
                            const deleteError = 'There was an error while deleting your assignment. Please Reload the page and try again.';
                            return showError(deleteError);
                        }
                        this.key += 1; // force refresh
                    },
                });
            }
            event.preventDefault();
        },
        save(updates) {
            const { showError, $store, gradeTemplate } = this;
            const { markingPeriods, assignments, gradingCategories } = this;
            const courseWorks = [];
            updates.forEach((item) => {
                const { courseWork, changes } = item;
                const { courseWorkId, googleCourseWorkId } = courseWork;

                const cw = assignments.find((o) => (courseWorkId && courseWorkId == o.courseWorkId) || (googleCourseWorkId && googleCourseWorkId == o.googleCourseWorkId));
                if (!cw) return showError('Course work not found');

                changes.forEach((change) => {
                    const { fieldName, newValue } = change;
                    if (fieldName == 'markingPeriod') {
                        // map it back to schoolTermMarkingPeriod
                        const markingPeriod = markingPeriods.find((mp) => mp.markingPeriod == newValue);
                        if (!markingPeriod) return showError('Could not find marking period');
                        cw.schoolTermMarkingPeriodId = markingPeriod.schoolTermMarkingPeriodId;
                        return; // move to next iteration
                    }
                    if (fieldName == 'categoryName') {
                        // map it back to schoolTermMarkingPeriod
                        const gradingCategory = gradingCategories.find((category) => category.categoryName == newValue) || null;
                        if (!gradingCategory) return showError('Could not find gradingCategory');
                        cw.gradeTemplateCategoryId = gradingCategory.gradeTemplateCategoryId;
                        cw.gradeCategoryId = gradingCategory.gradeCategoryId;
                        cw.categoryName = gradingCategory.categoryName;
                        return; // move to next iteration
                    }

                    if (cw.hasOwnProperty(fieldName)) {
                        cw[fieldName] = newValue;
                    } else {
                        return showError(`unknown ${fieldName} mapping to ${newValue}`);
                    }
                });
                courseWorks.push(cw);
            });
            const localCourseWorks = courseWorks.filter((cw) => cw.courseWorkDomain == 'Local');
            if (localCourseWorks.length > 0) {
                $store.dispatch(Types.actions.EDIT_LOCAL_COURSE_WORK, {
                    courseWorks: localCourseWorks,
                    gradeTemplate,
                    callback(err) {
                        if (err) return showError(err);
                    },
                });
            }

            const googleCourseWorks = courseWorks
                .filter((cw) => cw.courseWorkDomain == 'Google')
                .map((cw) => ({
                    gradeTemplateCategoryId: cw.gradeTemplateCategoryId,
                    googleCourseWorkId: cw.googleCourseWorkId,
                    courseWorkWeight: cw.courseWorkWeight,
                    averageExclusiveForMp: cw.averageExclusiveForMp,
                    schoolTermMarkingPeriodId: cw.schoolTermMarkingPeriodId,
                    hideMarks: cw.hideMarks,
                    excluded: cw.excluded,
                    teacherExtCourseWorkId: cw.teacherExtCourseWorkId,
                }));

            if (googleCourseWorks.length > 0) {
                $store.dispatch(Types.actions.EDIT_GOOGLE_COURSE_WORK_PROPERTIES, {
                    courseWorks: googleCourseWorks,
                    callback(err) {
                        if (err) {
                            console.error(err);
                            const saveError = 'There was a network error while saving your changes. Please Reload the page and try again.';
                            return showError(saveError);
                        }
                    },
                });
            }
        },
    },
});

function isNumber(n) {
    const validNumberError = validate.single(n, {
        numericality: {
            presence: false,
            greaterThanOrEqualTo: 0,
        },
    });
    return !validNumberError;
}

</script>

<style scoped src="../css/colors.scss" lang="scss" />

<style>
.ass-hot-table-container .handsontable td {
    vertical-align: middle;
}

.ass-hot-table-container .handsontable table.htCore thead tr th .colHeader {
    white-space: nowrap;
}

.ass-hot-table-container .handsontableInput {
    background-color: white !important;
}
.ass-hot-table-container .handsontable td.row-deleted {
    color: rgb(220, 53, 69) !important;
}

</style>
