var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("CourseSubHeader", {
        attrs: {
          "show-marking-period-picker": true,
          "increment-key": _vm.incrementKey,
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn btn-icon kt-subheader__btn",
                    class: { active: _vm.showCalendars },
                    attrs: {
                      href: "#",
                      title: _vm.showCalendars
                        ? "Hide Calendars"
                        : "Show Calendars",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        _vm.showCalendars = !_vm.showCalendars
                      },
                    },
                  },
                  [
                    _vm.showCalendars
                      ? _c("i", { staticClass: "flaticon2-calendar" })
                      : _c("i", { staticClass: "flaticon2-calendar-2" }),
                  ]
                ),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn btn-icon",
                    class: {
                      "btn-danger": _vm.showDeleted,
                      "btn-clean-danger btn-clean": !_vm.showDeleted,
                    },
                    attrs: {
                      href: "#",
                      title: _vm.showDeleted ? "Hide Deleted" : "Show Deleted",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        _vm.showDeleted = !_vm.showDeleted
                      },
                    },
                  },
                  [_c("i", { staticClass: "flaticon2-trash" })]
                ),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn btn-icon kt-subheader__btn",
                    class: { active: _vm.searchOpen },
                    attrs: {
                      href: "#",
                      title: _vm.searchOpen ? "Hide Filters" : "Show Filters",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        _vm.searchOpen = !_vm.searchOpen
                      },
                    },
                  },
                  [
                    _vm.searchOpen
                      ? _c("i", { staticClass: "flaticon2-search" })
                      : _c("i", { staticClass: "flaticon2-search" }),
                  ]
                ),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn kt-subheader__btn-primary btn-icon",
                    attrs: { href: "#", title: "Export To CSV" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.exportData.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "flaticon-download-1" })]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _c("div", { staticClass: "kt-portlet" }, [
            _c(
              "div",
              { staticClass: "kt-portlet__head kt-portlet__head--lg d-none" },
              [
                _c("div", { staticClass: "kt-portlet__head-label" }, [
                  _vm.icon
                    ? _c(
                        "span",
                        { staticClass: "kt-portlet__head-icon pr-0" },
                        [
                          _c("SVGIcon", {
                            staticClass: "mr-3",
                            attrs: { "hex-color": "#0f88ef", name: _vm.icon },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("h3", { staticClass: "kt-portlet__head-title" }, [
                    _c("span", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
                  ]),
                ]),
                _vm._m(0),
              ]
            ),
            _c(
              "div",
              { staticClass: "kt-portlet__body assignments-list" },
              [
                _c(
                  "b-collapse",
                  {
                    key: `cycle_${_vm.resetKey}`,
                    model: {
                      value: _vm.searchOpen,
                      callback: function ($$v) {
                        _vm.searchOpen = $$v
                      },
                      expression: "searchOpen",
                    },
                  },
                  [
                    _c("div", { staticClass: "form-group row mt-0 mb-1" }, [
                      _c("div", { staticClass: "col-sm-6 pt-3" }, [
                        _c("label", [_vm._v("Search Term")]),
                        _c(
                          "div",
                          { staticClass: "kt-input-icon kt-input-icon--left" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.search.filterOn,
                                  expression: "search.filterOn",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", placeholder: "Search..." },
                              domProps: { value: _vm.search.filterOn },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.search,
                                    "filterOn",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "kt-input-icon__icon kt-input-icon__icon--left",
                              },
                              [
                                _c("span", [
                                  _c("i", { staticClass: "la la-search" }),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-sm-3 pt-3" },
                        [
                          _c("label", {}, [_vm._v("Start Date")]),
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "form-control kt-input",
                                attrs: { type: "date", autocomplete: "off" },
                                model: {
                                  value: _vm.search.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "startDate", $$v)
                                  },
                                  expression: "search.startDate",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "date-info-fn": _vm.formatCalendarDay,
                                      "no-key-nav": true,
                                      "button-only": "",
                                      autocomplete: "off",
                                      locale: "en-US",
                                    },
                                    on: { context: _vm.onContextStart },
                                    model: {
                                      value: _vm.search.startDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "startDate", $$v)
                                      },
                                      expression: "search.startDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm-3 pt-3" },
                        [
                          _c("label", {}, [_vm._v("End Date")]),
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "form-control kt-input",
                                attrs: {
                                  type: "date",
                                  autocomplete: "off",
                                  disabled: _vm.search.startDate == "",
                                },
                                model: {
                                  value: _vm.search.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "endDate", $$v)
                                  },
                                  expression: "search.endDate",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "date-info-fn": _vm.formatCalendarDay,
                                      "no-key-nav": true,
                                      "button-only": "",
                                      autocomplete: "off",
                                      locale: "en-US",
                                      disabled: _vm.search.startDate == "",
                                    },
                                    on: { context: _vm.onContextEnd },
                                    model: {
                                      value: _vm.search.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "endDate", $$v)
                                      },
                                      expression: "search.endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm-6 offset-6 pt-3" },
                        [
                          _c("MultiPicker", {
                            key: _vm.gradeTemplateResetKey,
                            attrs: {
                              title: "Grading Categories",
                              items: _vm.gradingCategories,
                              "on-selected": _vm.gradingCategorySelected,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "form-group row mb-3" }, [
                  _c("div", { staticClass: "col-12 p-4" }, [
                    _vm.error
                      ? _c(
                          "div",
                          { staticClass: "alert alert-solid-danger mb-0 mt-2" },
                          [
                            _c("div", { staticClass: "alert-text" }, [
                              _vm._v(" " + _vm._s(_vm.error) + " "),
                            ]),
                          ]
                        )
                      : _vm.dataSource.length == 0
                      ? _c(
                          "div",
                          { staticClass: "alert alert-solid-danger mb-0 mt-2" },
                          [
                            _c("div", { staticClass: "alert-text" }, [
                              _vm._v(
                                " There are no assignments found. Change search options and try again. "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "kt-section__content kt-section__content--solid",
                      },
                      [
                        _vm.search.filterOn
                          ? _c(
                              "button",
                              {
                                key: _vm.search.filterOn,
                                staticClass:
                                  "btn btn-label-primary btn-pill m-2",
                                staticStyle: {
                                  "font-size": "12px",
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                  "max-width": "150px",
                                  "white-space": "nowrap",
                                },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearSpecificFilter("term")
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.search.filterOn) + " ")]
                            )
                          : _vm._e(),
                        _vm.selectedLabels.date.length
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-label-primary btn-pill m-2",
                                staticStyle: { "font-size": "12px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearSpecificFilter("date")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedLabels.date.join(" - ")
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectedLabels.markingPeriods.length
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-label-info btn-pill m-2",
                                staticStyle: { "font-size": "12px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearSpecificFilter(
                                      "markingPeriods"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedLabels.markingPeriods.join(
                                        ", "
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectedLabels.gradeTemplateCategories.length
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-label-success btn-pill m-2",
                                staticStyle: { "font-size": "12px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearSpecificFilter(
                                      "gradeTemplateCategories"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedLabels.gradeTemplateCategories.join(
                                        ", "
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectedLabels.googleTopics.length
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-label-primary btn-pill m-2",
                                staticStyle: { "font-size": "12px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearSpecificFilter(
                                      "googleTopics"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " G: " +
                                    _vm._s(
                                      _vm.selectedLabels.googleTopics.join(", ")
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectedLabels.googleCategories.length
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-label-primary btn-pill m-2",
                                staticStyle: { "font-size": "12px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearSpecificFilter(
                                      "googleCategories"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " G: " +
                                    _vm._s(
                                      _vm.selectedLabels.googleCategories.join(
                                        ", "
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm.showCalendars
                    ? _c(
                        "div",
                        {
                          staticClass: "col-12 p-4",
                          attrs: { id: "overflow-container" },
                        },
                        _vm._l(_vm.calendars, function (calendar, idx) {
                          return _c("b-calendar", {
                            key: `month_${calendar.name}`,
                            staticClass: "assignment-calendar",
                            class: calendar.isThisMonth ? "this-month" : "",
                            attrs: {
                              "data-idx": idx,
                              "hide-header": "",
                              "label-help": "",
                              value: calendar.startDate,
                              min: calendar.startDate,
                              max: calendar.endDate,
                              "no-key-nav": true,
                              "date-info-fn": _vm.formatCalendarDay,
                              "selected-variant":
                                "outline-none text-dark" /*this disables the selected date highlight */,
                            },
                            on: { selected: _vm.dateSelect },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ]),
                _vm.dataSource.length > 0 && _vm.isPortfolioReady
                  ? _c(
                      "div",
                      {
                        key: `key_${_vm.title}_${_vm.key}`,
                        staticClass: "table-container",
                      },
                      [
                        _c("AssignmentsHotTable", {
                          key: `key_${_vm.key}`,
                          ref: "wrapperAssignments",
                          attrs: {
                            course: _vm.courseSection,
                            "data-source": _vm.dataSource,
                            assignments: _vm.filteredAssignments,
                            "grade-template": _vm.gradeTemplate,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
      _c("div", { staticClass: "kt-portlet__head-actions" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }